import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/KYC',
        name: 'KYC',
        component: () => import('../views/KYC.vue'),
    },
    {
        path: '/DEMAT',
        name: 'DEMAT',
        component: () => import('../views/DEMAT.vue'),
    },
    {
        path: '/demat-account-closing-letter',
        name: 'DEMATAccountClosingLetter',
        component: () => import('../views/DCL.vue'),
    },
    {
        path: '/minor-to-individual-transfer',
        name: 'M2I',
        component: () => import('../views/M2I.vue'),
    },
    {
        path: '/securities-transfer-form',
        name: 'STF',
        component: () => import('../views/STF.vue'),
    },
    {
        path: '/letter-of-consent',
        name: 'LOC',
        component: () => import('../views/LOC.vue'),
    },
    {
        path: '/dematerialization-order',
        name: 'DF',
        component: () => import('../views/PF.vue'),
    },
    {
        path: '/posthumous-security-transfer',
        name: 'PHST',
        component: () => import('../views/PHST.vue'),
    },
    {
        path: '*',
        name: 'Home1',
        component: () => import('../views/Home.vue'),
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

export default router;
