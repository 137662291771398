<template>
  <v-app>
    <v-main>
      <app-header v-if="showNavBar" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    'app-header': Header,
    //s"app-footer": Footer,
  },
  created() {
    if (window.location.pathname.includes('debugger')) {
      this.showNavBar = false;
    } else {
      this.showNavBar = true;
    }
  },
  data: () => ({
    showNavBar: true,
  }),
};
</script>

<style>
:root {
  --green: #499000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.manrope {
  font-family: 'Manrope', sans-serif;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}
</style>
